import JSZip from "jszip";
import {changeTitleFile} from './downloadFile'
import {setFormat} from './calculations'
import axios from "axios";

export const  saveToZip = (urls) => {
    const zip = new JSZip()
    urls.forEach((element)=> {
        const blobPromise = fetch(element.url).then(r => {
            if (r.status === 200) return r.blob()
            return Promise.reject(new Error(r.statusText))
        })
        // console.log(element)
        if(element.type === 'folder'){
            zip.folder(element.name)
            axios.get('https://btb-downloader.herokuapp.com/api/images'+element.folderPath)
            .then(res => console.log(res.data))
        }
        // Jeżeli jest folder
        else
        {   
            const format = setFormat(element.url)
            const name = changeTitleFile(element,format)
            zip.file(name, blobPromise)
        }
        
        
    })
    return zip      
}