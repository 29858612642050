<template>
    <div class="listBlock w-40 h-40 sm:w-32 sm:h-32 md:w-36 md:h-36">
        <a class="cursor-pointer" @click="searchClick">
                <img :src="(miniUrl)" :class="[(loaded)? 'opacity-100':'opacity-0','ml-auto mr-auto opacity-0']"/>
                <p class="text-center titleBlock mt-2 sm:mt-0">{{title}}</p>
        </a>
    </div>
</template>

<script>
    import axios from 'axios'
    import transformTitle from '../parts/transformTitle'

    export default {
        name: 'folder',
        data(){
          return{
            miniUrl : '',
            loaded: false,
            title: ''
          }
        },
        props: ['files','url'],
       methods:{
          searchClick(){
            this.$emit("directory", this.files.folderPath)
          }
       },
        async created(){
            this.title = transformTitle(this.files.name)
            
           if(this.url === 'images' || this.url === 'images/'){
              await axios.get(`https://btb-downloader.herokuapp.com/api/images${this.files.folderPath}`)
              .then(response => {
                const data = response.data
                  data.forEach(element => {
                    const miniatureName = element.name.split('.')[0]
                    if(miniatureName === 'miniatura'){
                      this.miniUrl = element.thumbnail
                      this.loaded = true
                    }
                  });
                }

            )
          }
          else{
            this.miniUrl = '/img/folder.png'
            this.loaded = true
          }
        }
    }
    
</script>

<style scoped>
  .listBlock{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 2px 4px 9px -5px #aaa;
  }
  .listBlock a{
    width: 100%;
    height: 100%;
    display: block;
  }
  .listBlock a img{
    padding: 25px;
  }
  .titleBlock{
    font-size: .9em;
    padding-top: 5px;
  }
</style>