<template>
    <a class="cursor-pointer" @click="openFile" >
            <span class="formatLabel">{{format}}</span>
            <a class="downloadBlock" @click="downloadFile" :class="loadfiles? 'loading' : ''">
                <div class="downloadIcon">
                    <svg xmlns="http://www.w3.org/2000/svg" v-if="loadfiles" class="spinner" viewBox="0 0 24 24" width="24px" height="24px"><line x1="7.091" x2="9" y1="3.497" y2="6.804" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="3.497" x2="6.804" y1="7.091" y2="9" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="2.182" x2="6" y1="12" y2="12" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="3.497" x2="6.804" y1="16.909" y2="15" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="7.091" x2="9" y1="20.503" y2="17.196" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="12" x2="12" y1="21.818" y2="18" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="16.909" x2="15" y1="20.503" y2="17.196" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="20.503" x2="17.196" y1="16.909" y2="15" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="21.818" x2="18" y1="12" y2="12" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="20.503" x2="17.196" y1="7.091" y2="9" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="16.909" x2="15" y1="3.497" y2="6.804" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="12" x2="12" y1="2.182" y2="6" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
                    </svg>

                    <svg version="1.1" v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 128 128" style="enable-background:new 0 0 128 128;" xml:space="preserve">
                        <g id="_x37_">
                            <path id="_x32_" style="fill:#231F20;" d="M1.945,113.151V128h124.11v-14.849H1.945z M114.651,62.01l-50.487,42.558L14.834,61.951
                                l9.711-11.226l32.817,28.362V0h14.849v78.374l32.876-27.709L114.651,62.01z"/>
                        </g>
                        <g id="Layer_1">
                        </g>
                    </svg>
                </div>
            </a>
            <img :src="files.thumbnail" class="ml-auto mr-auto"/>
            <div class="paramLabel">
                <p>{{getWeight(files.size)}}</p>  
                <p>{{files.width + ' x ' + files.height + 'px' }}</p> 
            </div>
            
    </a>
</template>

<script>
     import {downloadSimpleFile} from '../parts/downloadFile'
     import {bytesToSize, setFormat} from '../parts/calculations'
     
    export default {
        name: 'File',
        data(){
            return{
                format: '',
                loadfiles: false
            }
        },
        props: ['files'],
        created() {
            this.format = setFormat(this.files.name)
        },
        methods: {
            getWeight(bytes){
               return bytesToSize(bytes)
            },
            openFile(){
                this.$emit('clickElement', this.files)
            },
            downloadFile(e){
                e.stopPropagation();
                downloadSimpleFile(this.files,this.format)
            }
        }
    }
    
</script>

<style scoped>
    .cursor-pointer{
        position: relative;
        display: block;
        background-color: #fff;
    }
    .cursor-pointer .formatLabel{
        background-color: #6ECFF5;
        color: #fff;
        padding: 0 5px;
        position: absolute;
        font-size: .8em;
        top:0;
        left: 0;
        z-index: 99;
    }
    .paramLabel{
        background-color: #fff;
        padding: 5px 10px;
        font-size: .9em;
        text-align: right;
        font-weight: 400;
    }
    .downloadBlock{
        display: none;
    }
    a.cursor-pointer:hover .downloadBlock .downloadIcon{
        position: relative;
        width: 100%;
        height: 100%;
        display: block;
    }
    
    a.cursor-pointer:hover .downloadBlock .downloadIcon .spinner{
        height: 20px;
        width: 20px;
        margin: 0;
        position: relative;
        top:0;
        left:0;
    }
    a.cursor-pointer:hover .downloadBlock.loading{
        padding: 5px;
    }
    a.cursor-pointer:hover .downloadBlock{
        position: absolute;
        display: block;
        width: 30px;
        height: 30px;
        padding: 7px;
        right: 0;
        top:0;
        z-index: 99;
        background: rgba(16, 185, 129);
    }
    a.cursor-pointer:hover .downloadBlock path{
        fill: #fff !important;
    }
</style>