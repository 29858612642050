<template>
 <div>
   <div :class="['toolBar relative pt-5 pb-10', (!isfolder)?'pb-20 sm:pb-10':'']">
     <div class="align-center text-cener" v-if="title">
       <h1 class="mainTitle">{{title}}</h1>
      </div>
     <div class="align-center text-cener" v-else>
       <h1 class="mainTitle text-2xl sm:text-3xl">Darmowe pliki do pobrania dla naszych klientów.
         <span>Korzystaj dowolnie w celach promocyjnych.</span>
       </h1>
       <p class="text-center p-2">Pamiętaj, aby zachować logo, jeśli jest widoczne na grafice. </p>
      </div>
    <div class="download absolute sm:right-0 w-full sm:w-auto text-center sm:text-right" v-if="!isfolder">
      <a  class="bg-green-500 p-5 rounded-lg hover:bg-green-600 cursor-pointer text-white" @click="downloadZip">Pobierz aktualny folder (zip)</a>
    </div>
   </div>
  
  <div id="filesSection">
    <template v-if="loading">
        <svg class="spinner" viewBox="0 0 50 50">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
      </svg>
    </template>

  <template v-else>
      
    <div v-if="url != 'images' && url !=='images/'" class="flex">
      <div class="w-24 font-bold text-center rounded-lg p-2 cursor-pointer mb-5 flex items-center border-2 border-black border-solid" @click="goToHome"> 
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" class="mr-1">
          <g>
            <path d="M19,30H11a1,1,0,0,1-1-1V22a5,5,0,0,1,10,0v7A1,1,0,0,1,19,30Zm-7-2h6V22a3,3,0,0,0-6,0Z" fill="#212121"/>
            <path d="M25,30H5a1,1,0,0,1-1-1V15a1,1,0,0,1,2,0V28H24V15a1,1,0,0,1,2,0V29A1,1,0,0,1,25,30Z" fill="#212121"/>
            <path d="M29,14a1,1,0,0,1-.65-.24L15,2.317,1.651,13.76a1,1,0,0,1-1.3-1.519l14-12a1,1,0,0,1,1.3,0l14,12A1,1,0,0,1,29,14Z" fill="#212121"/>
            <path d="M4,11a1,1,0,0,1-1-1V2A1,1,0,0,1,4,1H9a1,1,0,0,1,1,1V6A1,1,0,0,1,8,6V3H5v7A1,1,0,0,1,4,11Z" fill="#212121"/>
          </g>  
        </svg>
        Home     
        </div>
      <div class="w-24 font-bold  text-center rounded-lg p-2 cursor-pointer mb-5" @click="undo"> Wstecz</div>
    </div>
    <div class="list-none flex flex-wrap w-full" :class="(files[0].length > 8)? 'justify-between md:justify-center' : ''">
      <template v-if="files[0].length!==0">
        <div v-for="file in files[0]" :key="file.fileId"  class="m-1 sm:m-5 sm:mb-16 mb-8 md:w-36 listBlock"> 
              <ImageFile v-if="(file.type==='file')" :files="file" @clickElement="openPreviewMethod"/>
              <ImageFolder v-if="(file.type==='folder')" :files="file" :url="url" @directory="searchFiles"/>
        </div>
      </template>
        
      <div v-if="files[0].length ===0" class="w-full text-center text-2xl mt-6 text-blue-400 opacity-50">
          <p>Brak plików</p>
      </div>
      </div>

      <template v-if="openFile">
         <ImagePreview :openFile="openFile" :allFiles="files" @clickElement="openPreviewMethod"/>
      </template>
       <template v-if="downloadActualFolder">
         <DownloadProcess :openFile="files" @clickElement="openPreviewMethod" @stoppedAcion="stoppedDownload"/>
      </template>
     
  </template>
     
  </div>
 </div>
</template>

<script>
  import axios from 'axios'
  import ImageFile from './ImageFile'
  import ImageFolder from './ImageFolder'
  import ImagePreview from './ImagePreview'
  import DownloadProcess from './DownloadProcess'

  export default {
    data: () => {
      return {
        files: [],
        loading: true,
        titleCategory: '',
        openPreview: false,
        openFile: null,
        downloadActualFolder: false,
        isfolder: true
      }
    },
    components:{
      ImageFile,
      ImageFolder,
      ImagePreview,
      DownloadProcess
    },
    props: ['url','title'],
    async created(){
      
      await axios.get(`https://btb-downloader.herokuapp.com/api/${this.url}`)
      .then(res => {
        let logosFile = []
        let folderIsFound = []

        const sortArray = res.data.filter(value =>  {
         
          const noneMiniature = value.name.includes('miniatura')
          const logos = value.name.includes('Logos')
          
          if(value.type == 'folder' || noneMiniature){
            folderIsFound.push(value)
          }

          if(noneMiniature){
            return false
          }
          else if(logos){
            logosFile.push(value)
            return false
          }
          else{
            return true
          }
          })
       
        const sortingName = sortArray.sort(sortName)
        const sorting = sortingName.sort(sortType)
        this.files.push(sorting)
        if(logosFile[0] != undefined) this.files[0].unshift(logosFile[0])
       
        if(folderIsFound.length===0) this.isfolder = false 
         
        this.loading = false
      })

      function sortType( a, b ) {
        if ( a.type > b.type ){
          return -1;
        }
        if ( a.type < b.type ){
          return 1;
        }
        return 0;
      }

      function sortName(a,b){
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
      }

    },
    methods:{
      downloadZip(){
        this.downloadActualFolder = true
        // const zip = new JSZip()
        // zip.file("Hello.txt", "siemasz Ziom")

        // zip.generateAsync({type: "blob"}).then( content =>{
        //   saveAs(content, "example.zip");
        // })
      },
      searchFiles(value){
        this.$emit('directory', value)
      },
      undo(){
        const splitURL = this.url.split('/')
        splitURL.shift()
        splitURL.pop()
        const newPath = splitURL.join("/")
         this.$emit('directory', '/'+newPath)
      },
      goToHome(){
         this.$emit('directory', '/')
      },  
      openPreviewMethod(value){
        this.openFile = value
      },
      stoppedDownload(value){
         this.downloadActualFolder = value
      }
    }
  }
</script>

<style >
  #filesSection{
    min-height: 20vh;
    position: relative;
  }
  .spinner{
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;
  }
  .spinner .path {
    stroke: #2577BC;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  .mainTitle{
    font-size: 2em;
    text-align: center;
    font-weight: 500;
    display: grid;
  }
  .download{
    top: 65%;
  }
  @media screen and (min-width: 640px){
    .download{
    top: 50%;
    transform: translateY(-50%);
    }
  }


  @keyframes rotate {
  100% {
    transform: rotate(360deg);
    }
  }

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>