export const bytesToSize = (bytes) => {
    const sizes = [ 'B','KB', 'MB', 'GB']
      const  i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round((bytes / Math.pow(1024, i))*100)/100 + ' ' + sizes[i];
}

export const setFormat = (name) => {
  const fileFormatArray = name.split('.')
  const fileFormat = fileFormatArray[fileFormatArray.length-1].toUpperCase()
  return fileFormat
}