const imgloader= url => {

    const img = document.createElement('img')
    const prom = new Promise(function(res, rej){
        img.onload = () => res(url)
        img.onerror = () => rej(url)
    })
    img.src = url
    return prom
}

export default imgloader