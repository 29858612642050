
import { saveAs} from 'file-saver'

export const changeTitleFile = (fileObject, format) => {
    
    const path = fileObject.filePath.split('/')
    const nameFolder = path[1]
    const secondNamefolder = path[path.length-2]
    const formatFile = format.toLowerCase()
    let pathName = ''
    if(nameFolder === secondNamefolder){
        pathName = nameFolder + '_'
    }
    else{
        pathName = nameFolder + '_' + secondNamefolder + '_'
    }
    const randomCount = Math.floor(Math.random() * 1000)
    const urlDownload = pathName + randomCount + '.' + formatFile
    return urlDownload
}

export const downloadSimpleFile = (fileObject, format) => {
    const urlDownload = changeTitleFile(fileObject,format)
    const url = fileObject.url
    saveAs(url, urlDownload)
}