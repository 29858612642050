<template>
    <div class="downloaderBG">
        <div class="downloader">
            <div class="heading">
                <h2>Trwa generowanie pliku</h2>
                <p>Prosimy czekać...</p>
            </div>
            <div class="loader">
                <div class="w-full bg-gray-200 rounded-full dark:bg-gray-700 overflow-hidden">
                    <div class="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" :style="'width:' + progressCount + '%'"> {{progressCount + '%'}}</div>
                </div>
            </div>
           
        </div>
    </div>
</template>

<script>
  import {saveToZip} from '../parts/jszip' 
  import { saveAs } from "file-saver";
  import { changeTitleFile } from '../parts/downloadFile';

    export default  {
        name: 'DownloadProcess',
        data(){
            return{
                progressCount: 0
            }
        },
        props: ['openFile'],
        created(){
            this.imageZip(this.openFile[0])

        },
        methods: {
            imageZip(urls){

               saveToZip(urls).generateAsync({type:"blob"}, (metaData)=>{ 
                    const percent = metaData.percent;
                    this.progressCount = percent.toFixed(2) 
                })
                .then(blob => {
                    const name = changeTitleFile(this.openFile[0][0],'zip')
                    saveAs(blob, name)
                    setTimeout(()=> this.$emit("stoppedAcion", false) , 100)
                    
                    })
                    .catch(e => console.log(e));
            },
           
        }

    }
</script>

<style scoped>
    .downloaderBG{
        position: fixed;
        background-color: rgba(0,0,0,0.8);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99999;
    }
    .downloader{
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 500px;
        height: 250px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .downloader .heading{
        text-align: center;
        margin-bottom: 20px;
    }
    .downloader h2{
        font-size: 1.4em;
    }
    .downloader .loader{
        width: 70%;
        display: block;
    }
</style>