<template>
  <div class="">
    <Header></Header>
    <FilesArea></FilesArea>
    <Footer></Footer>
  </div>


</template>

<script>

import Header from './layout/Header'
import FilesArea from './layout/FilesArea'
import Footer from './layout/Footer'

export default {
  name: "App",
  components: {
    Header,
    FilesArea,
    Footer
  }
}

</script>

<style >
  body{
    background-color: #f1f4fd;
    }
</style>