<template>
    <header>
        <div class="container mx-auto flex p-5">
            <div class="logo">
                <a href="/">
                    <img src="/img/BTP_logo.png" alt="BTP platform"/>
                </a>
            </div>
            <h1></h1>
        </div>
        
    </header>


</template>

<script>

export default {
    name: "Header"
}

</script>

<style scoped>
    header{
        width: 100%;
        background: linear-gradient(90deg, #2172b9 0%, #6fd0f6 100%);
    }
    .logo{
        width: 100px;
    }
</style>