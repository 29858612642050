<template>

    <div id="productView" @click.stop="closeWindow">
        <div class="relative h-full" >
            <div class="productWindow rounded-md w-5/6 sm:w-1/2" @click.stop>
                <div class="naviChange hidden sm:flex">
                 
                        <div class="slideToLeft">
                            <a class="slideDirection" @click="changeSlide(-1)" :disabled="blockDirection==='left' || blockDirection==='none'">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#dadada" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
                                </svg>
                            </a>
                        </div>
                        <div class="slideToRight">
                              <a class="slideDirection" @click="changeSlide(1)" :disabled="blockDirection==='right' || blockDirection==='none'">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#dadada" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                </svg>
                              </a>
                        </div>
               
                </div>
                <div class="tollbarPreview">
                    <div class="close">
                        <a @click="closeWindow" class="closeButton">x</a>
                    </div>
                </div>
                <div class="textBlockPreview w-11/12 flex justify-between items-center mx-auto">
                    <div>
                        <div><span class="font-medium">Format:</span> {{format}}</div>
                        <div><span class="font-medium">Rozmiar:</span> {{getWeight(this.actualFile.size)}}</div>
                    </div>
                    <a class="bg-green-500 p-2 pl-4 pr-4 rounded-md hover:bg-green-600 cursor-pointer text-white relative" :class="loading ? 'loading' :''" @click="downloadFile">
                      <svg xmlns="http://www.w3.org/2000/svg" v-if="loading" class="spinner" viewBox="0 0 24 24" width="24px" height="24px"><line x1="7.091" x2="9" y1="3.497" y2="6.804" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="3.497" x2="6.804" y1="7.091" y2="9" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="2.182" x2="6" y1="12" y2="12" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="3.497" x2="6.804" y1="16.909" y2="15" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="7.091" x2="9" y1="20.503" y2="17.196" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="12" x2="12" y1="21.818" y2="18" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="16.909" x2="15" y1="20.503" y2="17.196" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="20.503" x2="17.196" y1="16.909" y2="15" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="21.818" x2="18" y1="12" y2="12" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="20.503" x2="17.196" y1="7.091" y2="9" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="16.909" x2="15" y1="3.497" y2="6.804" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/><line x1="12" x2="12" y1="2.182" y2="6" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
                    </svg>
                    <p v-else>Pobierz</p>
                    </a>
                </div>
                <div class="imageSection">
                     <template v-if="!loaded">
                            <svg class="spinner" viewBox="0 0 50 50">
                            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                        </svg>
                    </template>
                    <template v-else>
                        <img :src="actualFile.url + '?tr=w-600'" />
                    </template>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import imgloader from '../parts/imgloader'
    import {downloadSimpleFile} from '../parts/downloadFile'
    import { bytesToSize, setFormat } from '../parts/calculations'

    export default {
        name: 'Preview',
        data(){
            return{
                loaded : false,
                format: '',
                loading: false,
                actualFile: this.openFile,
                blockDirection: '',
                actualIndex : 0
            }
        },
        props: ['openFile','allFiles'],
        created(){
            imgloader(this.openFile.url)
            .then(() => this.loaded=true)

            this.format = setFormat(this.openFile.name)
            this.changeSlide(0)
            if(this.allFiles[0].length===1) this.blockDirection = 'none'
           
            window.addEventListener('keydown', e =>{
                switch (e.key){
                    case 'ArrowLeft':
                         this.changeSlide(-1)
                    break;
                    case 'ArrowRight':
                         this.changeSlide(1)
                    break;
                    case 'Escape':
                         this.closeWindow()
                    break;
                }
            })
        },
        methods: {
            closeWindow(){
                this.$emit('clickElement', null)
            },
            getWeight(size){
                return bytesToSize(size)
            },
            downloadFile(){
                 downloadSimpleFile(this.openFile,this.format)
            },
            changeSlide(direction){

               this.allFiles[0].forEach((file, index) => {
                    (file.fileId === this.actualFile.fileId)? this.actualIndex = index : null
                })
                
                let directionIndex = new Number(this.actualIndex) + direction
               
                if(directionIndex>=this.allFiles[0].length-1){
                    this.blockDirection = 'right'
                    this.actualIndex = this.allFiles[0].length-1
                     directionIndex = this.allFiles[0].length-1
                }
                else if(directionIndex<=0){
                    this.blockDirection = 'left'
                    this.actualIndex = 0
                    directionIndex = 0
                }
                else{
                    this.blockDirection = ''
                    this.actualIndex = directionIndex
                }
                
                    this.actualFile = this.allFiles[0][directionIndex]
                    
            }

        }
    }

</script>

<style>
    #productView{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background-color: rgba(0,0,0,0.8);
    }
    #productView .productWindow{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: #fff;
        color: #000;
        padding: 10px;
        max-height: 90%;
    }
    .tollbarPreview{
        width: 100%;
        text-align: right;
        display: flex;
        justify-content: right;
    }
    .tollbarPreview .close{
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
    }
    .tollbarPreview .close .closeButton{
        font-weight: 600;
        cursor: pointer;
        font-size: 1.5em;
        color: #111;
    }
    .imageSection{
        min-height: 50vh;
        max-height:75vh;
        overflow: hidden;
    }
    .imageSection img{
        margin: 0 auto;
        max-width: 90%;
    }
    .textBlockPreview{
        overflow: hidden;
        margin-bottom: 20px;
    }
    .textBlockPreview a{
        width: 100px;
        height: 40px;
        text-align: center;
    }
   
    .textBlockPreview a.loading svg{
        width: 20px;
        height: 20px;
        margin:-10px;
    }
    .naviChange{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 120%;
        display: flex;
        justify-content: space-between;
    }
    .naviChange svg{
        width: 50px;
    }
    .slideDirection{
        cursor: pointer;
    }
    .slideDirection[disabled]{
        display: none;
    }
   
</style>