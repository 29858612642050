import Vue from 'vue'
import VueCompositionAPI from "@vue/composition-api";
import App from './App.vue'
import './assets/tailwind.css'
import Feature from './components/Feature'
import ImageKit from "imagekitio-vue"

Vue.use(ImageKit, {
  urlEndpoint: "https://ik.imagekit.io/tomi1618", // Required. Default URL-endpoint is https://ik.imagekit.io/your_imagekit_id
})

Vue.config.productionTip = false

Vue.use(VueCompositionAPI);

Vue.component("Feature", Feature)

new Vue({
  render: h => h(App),
}).$mount('#app')
