<template>
    <div class="mt-10">
        <Feature :url='"images"+ url' :title="title" @directory="searchFiles" :key="reload"/>
    </div>
</template>

<script >
import Feature from './Feature'
import transformTitle from '../parts/transformTitle'
   export default {
       data(){
           return {
               url : '',
               title : '',
               reload : 0
           }
       },
       components: {
           Feature
       },
       methods: {
           searchFiles(value){
               this.url = value
               this.reload += 1
               this.changeUrlToTitle()
           },
            changeUrlToTitle(){
                const lastElement = this.url.split('/')
                let titleCategory= lastElement[lastElement.length-1]
                titleCategory = transformTitle(titleCategory)
                this.title = titleCategory 
            }

       }
   }


</script>

<style>


</style>