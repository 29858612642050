<template>
    <div class="mx-3 md:mx-24 mt-10">
       
        <div class="banner"> 
            <img src="../assets/banner.jpg">
            <div class="banner__title relative sm:absolute text-center sm:text-left">
                <h1>Materiały reklamowe</h1>
            </div>
        </div>
       <FilesBlock></FilesBlock>
    </div>
</template>

<script>
import FilesBlock from '../components/FilesBlock'

export default {
    name: "FilesArea",
    components:{
        FilesBlock
    }
}
</script>

<style>
    .banner{
        width: 100%;
        position: relative;
    }
     .banner .banner__title{
        bottom: 0px;
        left: 0;
        z-index: 9;
        padding: 20px 50px;
        background-color: rgba(0,0,0,0.6);
     }
     .banner h1{
        font-size: 1.5em;
        color:#fff;
        text-transform: uppercase;
    }
    
</style>